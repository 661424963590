<template>
  <nav>
    <router-link to="/">Accueil</router-link> |
      <router-link to="/hiragana">Hiragana</router-link> |
      <router-link to="/katakana">Katakana</router-link> |
    <router-link to="/vocabulary">Vocabulaire</router-link> |
    <router-link to="/kanji">Kanji</router-link> |
  </nav>
  <router-view />
</template>

<style>
body {
  background-color: black;
  color: white;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: white;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
<script setup>
</script>
