<template>
  <div class="home">
    <div class="questionnaire" v-show="question.jp !== undefined">
      <div class="current-question">
        Question : {{ this.currentQuestion + 1 }} / {{ this.maxQuestion }}
      </div>
      <div class="question-container">
        <span> {{ questionLabel }}</span>
        <span class="jap">
          {{ typeOfTest === "kanji" ? question.kanji : question.jp }}
        </span>
        <input v-model="answer" type="text" placeholder="reponse" />
        <button @click="submit">Valider</button>
      </div>
      <div class="score">
        Score : {{ this.points }} / {{ this.currentQuestion + 1 }}
        <div class="feedback jap" v-show="this.testEnded">
          <span v-show="this.points === this.currentQuestion + 1">
            おめでとう !
          </span>
          <span v-show="this.points < this.currentQuestion + 1">
            がんばって !
          </span>
        </div>
      </div>
      <div class="error" v-show="this.testEnded === true">
        <h3 v-show="this.errors.length >= 1">Erreurs:</h3>
        <div v-for="error in errors" :key="error.ro">
          <span class="jap">{{ error.jp }} => {{ error.ro }}</span>
        </div>
      </div>
    </div>
    <div
      class="clickable"
      @click="launchTest('hiragana')"
      v-show="testEnded === true"
    >
      <h2>Lancer un test sur les hiragana !</h2>
    </div>
    <div
      class="clickable"
      @click="launchTest('katakana')"
      v-show="testEnded === true"
    >
      <h2>Lancer un test sur les katakana !</h2>
    </div>
    <div
      class="clickable"
      @click="launchTest('mix')"
      v-show="testEnded === true"
    >
      <h2>Lancer un test mixte (hira + kata) !</h2>
    </div>
    <div
      class="clickable"
      @click="launchTest('vocabulary')"
      v-show="testEnded === true"
    >
      <h2>Lancer un test de vocabulaire !</h2>
    </div>
    <div
      class="clickable"
      @click="launchTest('kanji')"
      v-show="testEnded === true"
    >
      <h2>Lancer un test sur les kanji !</h2>
    </div>
  </div>
</template>

<script>
import hiragana from "@/assets/hiragana.json";
import katakana from "@/assets/katakana.json";
import vocabulary from "@/assets/words.json";
import kanji from "@/assets/kanji.json";

export default {
  name: "HomeView",
  data() {
    return {
      answer: "",
      question: {},
      maxQuestion: 15,
      points: 0,
      currentQuestion: 0,
      previousQuestions: [{}],
      errors: [],
      testEnded: true,
      typeOfTest: "",
    };
  },
  methods: {
    launchTest: function (type) {
      this.points = 0;
      this.currentQuestion = 0;
      this.previousQuestions = [{}];
      this.errors = [];
      this.testEnded = false;
      this.typeOfTest = type;
      this.maxQuestion = 15;
      if (this.typeOfTest === "kanji") {
        this.maxQuestion = 5;
      }

      this.getQuestion();
    },
    submit: function () {
      var expectedAnswer =
        this.typeOfTest === "kanji" || this.typeOfTest === "vocabulary"
          ? this.question.fr
          : this.question.ro;
      if (this.answer.trim().toLowerCase() === expectedAnswer) {
        this.points++;
        console.log("bonne réponse");
      } else {
        this.errors.push(this.question);
        console.log("mauvaise réponse");
      }
      this.answer = "";
      if (this.currentQuestion === this.maxQuestion - 1) {
        this.testEnded = true;
        console.log("fin du test");
      } else {
        this.currentQuestion++;
        this.getQuestion();
      }
    },
    getQuestion: function () {
      this.question = this.retrieveData();
      this.previousQuestions.push(this.question);
    },
    retrieveData: function () {
      var item = {};
      switch (this.typeOfTest) {
        case "hiragana":
          item = hiragana[Math.floor(Math.random() * hiragana.length)];
          break;
        case "katakana":
          item = katakana[Math.floor(Math.random() * katakana.length)];
          break;
        case "mix":
          var all = [];
          for (let i = 0; i < hiragana.length; i++) {
            all.push(hiragana[i]);
          }
          for (let i = 0; i < katakana.length; i++) {
            all.push(katakana[i]);
          }
          item = all[Math.floor(Math.random() * all.length)];
          break;
        case "vocabulary":
          item = vocabulary[Math.floor(Math.random() * vocabulary.length)];
          break;
        case "kanji":
          item = kanji[Math.floor(Math.random() * kanji.length)];
          break;
      }
      console.log(item);
      if (
        item.jp === "" ||
        this.previousQuestions.filter((e) => e.jp === item.jp).length > 0
      ) {
        return this.retrieveData();
      }
      return item;
    },
  },
  computed: {
    questionLabel() {
      switch (this.typeOfTest) {
        case "hiragana":
        case "katakana":
          return "Que signifie :";
        case "kanji":
        case "vocabulary":
          return "Donner la traduction de :";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}
.questionnaire {
  background-color: #555;
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 10px 10px 25px 10px;
  font-size: 1.2em;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.current-question {
  align-self: flex-start;
}
.score {
  text-decoration: underline;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
}
.question-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.jap {
  font-size: 2em;
}
</style>
